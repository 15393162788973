<template>
  <Modal
    :show="Ekle"
    :title="$t('GorevPlani.olustur')"
    :save-text="$t('GorevPlani.kaydet')"
    @close="cModal"
    @saveMethod="Olustur"
  >
    <!--@close="Ekle = false" -->
    <template v-slot:form>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="data.baslama_tarihi"
                :label="$t('GorevPlani.baslama_tarihi')"
                type="date"
                required
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="data.baslama_saati"
                :label="$t('GorevPlani.baslama_saati')"
                type="time"
                required
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="data.bitis_tarihi"
                :label="$t('GorevPlani.bitis_tarihi')"
                type="date"
                required
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="data.bitis_saati"
                :label="$t('GorevPlani.bitis_saati')"
                type="time"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <personel-select :label="$t('GorevPlani.PERSONEL_SECIN')" />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-autocomplete
                v-model="data.musteri_id"
                :items="Musteriler"
                hide-details
                :label="$t('GorevPlani.MUSTERI_SECIN')"
                item-text="adi"
                item-value="id"
                @change="MusteriSecildi"
              >
              </v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-autocomplete
                v-model="data.adres_id"
                :items="MusteriAdresleri"
                hide-details
                :label="$t('GorevPlani.ADRES_SECIN')"
                :item-text="item => `${item.cadde_nr} , ${item.postakodu_sehir} , ${item.ulke}`"
                item-value="id"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </template>
  </Modal>
</template>

<script>
import { mdiWindowClose } from '@mdi/js'
import Modal from '@/components/Modal'
import PersonelSelect from '@/components/PersonelSelect'
import dayjs from 'dayjs'

export default {
  name: 'GorevPlaniOlustur',
  components: { PersonelSelect, Modal },
  data() {
    return {
      data: {
        personel: null,
        musteri_id: null,
        adres_id: null,
        firma_id: this.$store.state.firma.id,
        baslama_tarihi: this.startDay(),
        bitis_tarihi: this.endDay(),
        baslama_saati: '09:00',
        bitis_saati: '19:00',
      },
      icons: {
        mdiWindowClose,
      },
    }
  },
  computed: {
    Ekle: {
      get() {
        return this.$store.state.createDialog
      },
      set(bool) {
        this.$store.state.createDialog = bool
      },
    },
    Musteriler() {
      return this.$store.state.Musteriler.Musteriler
    },
    MusteriAdresleri() {
      return this.$store.state.Musteriler.MusteriAdresleri
    },
    PersonelSelect() {
      return this.$store.state.Personeller.SelectPersonel
    },
    varSayilanFirma() {
      return this.$store.state.firma
    },
  },
  mounted() {
    this.$store.dispatch('Action', { name: 'Musteriler/MusterilerListesi' })
  },
  methods: {
    cModal() {
      this.$store.commit('Personeller/SelectPersonel', [])
      this.Ekle = false
    },
    startDay() {
      return this.dateFormat(dayjs().add(1, 'day'))
    },
    endDay() {
      return this.dateFormat(dayjs().add(8, 'day'))
    },
    dateFormat(dayjsDate) {
      return dayjsDate.format('YYYY-MM-DD')
    },
    MusteriSecildi(item) {
      const data = { id: item }
      this.$store.dispatch('Action', { name: 'Musteriler/MusteriAdresleriTumu', data }).then(() => {
        const adresSayisi = this.MusteriAdresleri?.length || 0
        // eslint-disable-next-line prefer-destructuring
        if (adresSayisi > 0) this.data.adres_id = this.MusteriAdresleri[0].id
      })
    },
    Olustur() {
      this.data.personel = this.PersonelSelect
      // eslint-disable-next-line no-underscore-dangle
      this.data.firma_id = this.varSayilanFirma.id
      this.$store.dispatch('Action', { name: 'GorevPlani/GorevPlaniOlustur', data: this.data }).then(() => {
        this.$emit('setReload', true)
        this.Ekle = false
        this.$store.commit('Personeller/SelectPersonel', [])
        this.data = {
          personel: null,
          musteri_id: null,
          adres_id: null,
          firma_id: null,
          baslama_tarihi: null,
          bitis_tarihi: null,
          baslama_saati: null,
          bitis_saati: null,
        }
      })
    },
  },

}
</script>

<style scoped>

</style>
