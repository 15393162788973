<template>
  <div>
    <v-card>
      <v-card-title>
        {{ $t('GorevPlani.Gorev_Takvimi') }}
        <v-icon
          color="primary"
          size="35"
          style="margin-left: 15px"
          @click="tarihSec=true"
        >
          {{ icons.mdiCalendarRange }}
        </v-icon>
      </v-card-title>
      <v-card-title v-if='Object.keys(selectedFiltre).length>0'>
        {{$t('GorevPlani.secilen_filtreler')}}
        <v-icon v-if='Object.keys(selectedFiltre).length>0'
                color="primary"
                size="35"
                style="margin-left: 15px"
                @click="clearTakvimFiltre"
        >
          {{ icons.mdiWindowClose  }}
        </v-icon>
      </v-card-title>
      <div style='padding:16px 20px' v-if='Object.keys(selectedFiltre).length>0'>
        Başlangıç Tarihi : {{selectedFiltre.start_date!='' ? parseTarih(selectedFiltre.start_date) : ''}} -
        Bitiş Tarihi : {{selectedFiltre.end_date!='' ? parseTarih(selectedFiltre.end_date) : ''}} -
        Personel : {{selectedFiltre.personal}}
      </div>
      <div class="info-panel">
        <div class="info-item">
          <span class="odeme-yapildi istatistik-kutu"></span>
          <span :class="dark ? 'lighttext' : 'darktext'">Personel Ödemesi Yapıldı</span>
        </div>
        <div class="info-item">
          <span class="odeme-alindi istatistik-kutu"></span>
          <span :class="dark ? 'lighttext' : 'darktext'">Müşteri Ödemesi Yapıldı</span>
        </div>
        <div class="info-item">
          <div class="fatura-kesildi istatistik-kutu"></div>
          <span :class="dark ? 'lighttext' : 'darktext'">Fatura kesildi.</span>
        </div>
        <div class="info-item">
          <span class="kontrol-edildi istatistik-kutu"></span>
          <span :class="dark ? 'lighttext' : 'darktext'">Mütabakat Yapıldı.</span>
        </div>
        <div class="info-item">
          <span class="planlandi istatistik-kutu"></span>
          <span :class="dark ? 'lighttext' : 'darktext'">Planlandı.</span>
        </div>
      </div>
      <v-data-table
        :headers="headers"
        class="table"
        :items="veri"
        :search="arama"
        :custom-filter="filterGorevTakvim"
        hide-default-header
      >
        <template #header="{ props: { headers } }">
          <thead class="v-data-table-header">
            <tr>
              <th
                v-for="header in headers"
                :key="header.value"
              >
                {{ translateDay(header.text) }}
              </th>
            </tr>
          </thead>
        </template>
        <template v-slot:item="{ item,index }">
          <tr :class="dark ? 'firma-dark' : 'firma'">
            <!-- SIRA  BAŞ.-->
            <td rowspan="2">
              {{ item.sira }}
            </td>
            <!-- SIRA  SON-->
            <!-- ÇALIŞMA YERİ  BAŞ.-->
            <td style="text-align: right;min-width: 200px">
              Arbeitsort
            </td>
            <!-- ÇALIŞMA YERİ  SON.-->
            <!-- ŞEHİRLER BAŞ.-->
            <td
              v-for="(sehir,i) in item.sehirler"
              :key="i"
              :colspan="sehir.tekrar"
              style="text-align: left"
            >
              {{ sehir.isim }}
            </td>
            <!-- ŞEHİRLER SON.-->
          </tr>
          <tr>
            <!-- İSİM BAŞ.-->
            <td style="text-align: left">
              {{ item.isim }}
            </td>
            <!-- İSİM SON.-->

            <!-- ŞİRKET KODLARI BAŞ.-->
            <td
              v-for="(sirket,i) in item.sirketler"
              :key="i"
              :class="StatusClass(sirket.status)"
              style="color:#000000 !important"
            >
              {{ sirket.kod }}
            </td>
            <!-- ŞİRKET KODLARI SON.-->
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <Modal
      :show="tarihSec"
      :title="$t('GorevPlani.tarih_sec')"
      :save-text="$t('GorevPlani.filtrele')"
      @close="CloseFiltre"
      @saveMethod="triggerSaveFiltre"
    >
      <template v-slot:form>
        <v-text-field
          v-model="start_date"
          :label="$t('GorevPlani.baslama_tarihi')"
          type="date"
          dense
          class="mt-3"
        ></v-text-field>
        <v-text-field
          v-model="end_date"
          :label="$t('GorevPlani.bitis_tarihi')"
          type="date"
          dense
          class="mt-3"
        ></v-text-field>
        <PersonelSelect />
      </template>
    </Modal>
    <GorevPlaniOlustur />
  </div>
</template>

<script>
import { mdiCalendarRange, mdiTrashCan,mdiWindowClose } from '@mdi/js'
// eslint-disable-next-line import/extensions
import Modal from '@/components/Modal'
import PersonelSelect from '@/components/PersonelSelect'
import GorevPlaniOlustur from '@/views/gorev-plani/gorev-plani-olustur'

export default {
  name: 'GorevTakvimi',
  components: { GorevPlaniOlustur, PersonelSelect, Modal },
  data() {
    return {
      tarihSec: false,
      selectedFiltre: {},
      icons: {
        mdiCalendarRange,
        mdiTrashCan,
        mdiWindowClose,
      },
    }
  },
  computed: {
    end_date: {
      get() {
        return this.$store.state.GorevPlani.EndDate
      },
      set(date) {
        this.$store.commit('GorevPlani/EndDateTanimla', date)
      },
    },
    start_date: {
      get() {
        return this.$store.state.GorevPlani.StartDate
      },
      set(date) {
        this.$store.commit('GorevPlani/StartDateTanimla', date)
      },
    },
    headers() {
      return this.$store.state.GorevPlani.GorevTakvim.headers
    },
    veri() {
      return this.$store.state.GorevPlani.GorevTakvim.veri
    },
    dark() {
      return this.$store.state.DarkMode
    },
    arama() {
      return this.$store.state.arama
    },
    personeller() {
      return this.$store.state.Personeller.PersonelList
    },
    selectedPersonel() {
      return this.$store.state.Personeller.SelectPersonel
    },
  },
  mounted() {
    this.SaveFiltre()
  },
  methods: {
    clearTakvimFiltre() {
      this.$store.commit('GorevPlani/StartDateTanimla', null)
      this.$store.commit('GorevPlani/EndDateTanimla', null)
      this.$store.commit('Personeller/SelectPersonel', [])
      this.selectedFiltre = {}
      this.SaveFiltre()
    },
    generateUID() {
      let firstPart = (Math.random() * 46656) | 0
      let secondPart = (Math.random() * 46656) | 0
      firstPart = (`000${firstPart.toString(36)}`).slice(-3)
      secondPart = (`000${secondPart.toString(36)}`).slice(-3)

      return firstPart + secondPart
    },
    filterGorevTakvim(value, search, item) {
      const cloned = JSON.parse(JSON.stringify(item))
      Object.keys(cloned).forEach(key => {
        if (Array.isArray(cloned[key])) {
          const uniqueTx = this.generateUID()
          if (key == 'sehirler') {
            cloned[key].forEach(d => {
              if (d.isim != null && d.isim != '') {
                cloned[`string_${uniqueTx}`] = d.isim
              }
            })
            delete cloned[key]
          } else {
            cloned[key].forEach(d => {
              if (d.kod != null && d.kod != '') {
                cloned[`string_${uniqueTx}`] = d.kod
              }
            })
            delete cloned[key]
          }
        }
      })

      return Object.values(cloned).some(prop => String(prop).toLowerCase().includes(search.toLowerCase()))
    },
    preViewColspan(index) {
      console.log(this.veri[index])
    },
    translateDay(day) {
      if (!day.includes('-')) return `${day.split(' ')[0]} ${this.$t(day.split(' ')[1])}`

      const [baslama, bitis] = day.split('-')

      return `${baslama.split(' ')[0]} ${this.$t(baslama.split(' ')[1])} - ${bitis.split(' ')[0]} ${this.$t(bitis.split(' ')[1])} `
    },
    CloseFiltre() {
      this.tarihSec = false
    },

    parseTarih(tarih) {
      const parsed = tarih.split('-')

      // console.log(parsed)
      const parsedDay = parsed[2].split(' ')

      return `${parsedDay[0]}.${parsed[1]}.${parsed[0]}`
    },
    triggerSaveFiltre() {
      // eslint-disable-next-line no-const-assign,no-plusplus,no-empty
      const selected = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.personeller.length; i++) {
        const pers = this.personeller[i]
        // eslint-disable-next-line no-plusplus
        for (let y = 0; y < this.selectedPersonel.length; y++) {
          const selectedpers = this.selectedPersonel[y]
          if (pers.id === selectedpers) {
            selected.push(pers.name)
          }
        }
      }
      this.selectedFiltre.start_date = this.start_date
      this.selectedFiltre.end_date = this.end_date
      this.selectedFiltre.personal = selected.join(',')
      this.SaveFiltre()
    },
    SaveFiltre() {
      this.$store.dispatch('Action', { name: 'GorevPlani/GorevPlaniTakvim' })
      this.tarihSec = false
    },
    // eslint-disable-next-line consistent-return
    StatusClass(status) {
      const text = this.$store.state.DarkMode ? 'darktext ' : 'lighttext '
      if (status === '1') {
        return `${text}kontrol-edildi`
      }
      if (status === '2') {
        return `${text}fatura-kesildi`
      }
      if (status === '3') {
        return `${text}odeme-alindi`
      }
      if (status === '4') {
        return `${text}odeme-yapildi`
      }
      if (status === '5') {
        return `${text}planlandi`
      }
    },
  },
}

/**
 * 1. İşlem Kontrol edildi (Kırmızı)
 * 2. Fatura Kesildiğinde Sarı olacak
 * 3. Ödeme alındı (Mavi)
 * 4. Personel Ödemesi yapıldı (Yeşil)
 * 5. İşlem Planlama Renksiz.
 */

//     sehirler: [
//       { isim: 'Eschbach', tekrar: 4 },
//       { isim: 'Toulouse (FR)', tekrar: 3 },
//       { isim: '', tekrar: 2 },
//       { isim: 'Duisburg', tekrar: 6 },
//       { isim: 'Oberhausen', tekrar: 10 },
//       { isim: 'Hürt', tekrar: 4 },
//     ],

</script>

<style scoped>
table,.v-data-table > .v-data-table__wrapper > table {
  background-color: #ffffff;
  border: 1px solid #000000;
  color: #000000;
  width: 100%;
}
.v-application--is-ltr .v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > tr
{
    padding:2px 5px;
    border-collapse: collapse;
    border: 1px solid #000000;
    text-align: center;
    height: auto;
    width: auto;
  }
.firma {
  font-weight: 600;
  background-color: #c7c7c7;
}

  .firma-dark {
    font-weight: 600;
    background-color: #493c58;
  }
  .kontrol-edildi {
    background-color: #e59f9f;
  }
  .fatura-kesildi {
    background-color: #f1e194;
  }
  .odeme-alindi {
    background-color: #94d4f1;
  }
  .odeme-yapildi {
    background-color: #a2f194;
  }
  .istatistik-kutu {
    width: 30px;
    height: 30px;
    display: inline-block;
  }
  .info-panel {
    display: flex;
    color: #000000;
    align-items: center;
    gap: 15px;
    padding: 20px;
  }
  .info-item {
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;
  }
  .table {
    width: 100%;
    justify-content: center;
  }
  .darktext {
    color: #3b355a !important;
  }
  .lighttext {
    color: #ffffff;
  }
  .theme--light.v-data-table th,
  .theme--light.v-data-table td{
    color: #000000 !important;
  }

  .theme--dark.v-data-table th,
  .theme--dark.v-data-table td {
    color: #ffffff !important;
  }
</style>
